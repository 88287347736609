import React from 'react'
import { Helmet } from 'react-helmet'
import classNames from 'classnames/bind'

import Layout from 'components/ui/Layout'
import Loader from 'components/ui/Loader'
import SEO from 'components/core/SEO'
import Hero from 'components/ui/Hero'
import SliceZone from 'components/ui/SliceZone'

import { HomepageType } from './HomepageTypes'

import * as s from './Homepage.module.scss'
const cn = classNames.bind(s)

const Homepage = ({ data }: HomepageType) => {
  if (!data) return null
  const document = data.allPrismicHomepage.edges[0].node.data
  const slices = data.allPrismicHomepage.nodes[0].data.body || []
  const introImages = { desktop: data.desktopAssets, mobile: data.mobileAssets }
  const footer = {
    info1: document.info_1,
    info2: document.info_2,
    info3: document.info_3,
    author: document.author,
    quote: document.quote,
    theme: 'Blue',
  }
  const nextPage = document.next_page

  return (
    <>
      <Helmet>
        <html data-bg-grey data-homepage />
      </Helmet>
      <SEO />
      <Loader />
      <Layout className={cn('home')} footerData={footer} nextPage={nextPage}>
        {/* ------------- */}
        {/* Hero /w Intro */}
        {/* ------------- */}
        <Hero
          title={document.hero_title.raw[0].text}
          subtitle={document.hero_subtitle.raw[0].text}
          image={document.hero_image.localFile.childImageSharp.fluid}
          alt={document.hero_image.alt}
          introImages={introImages}
        />
        {/* ------------- */}
        {/* Slices */}
        {/* ------------- */}
        <SliceZone slices={slices} page='homepage' />
      </Layout>
    </>
  )
}

export default Homepage
