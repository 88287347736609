import { graphql } from 'gatsby'

import Homepage from 'views/Homepage'

export const query = graphql`
  query HomepageQuery {
    desktopAssets: allFile(
      filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" }, relativeDirectory: { eq: "images/intro/desktop" } }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    mobileAssets: allFile(
      filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" }, relativeDirectory: { eq: "images/intro/mobile" } }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(quality: 90, maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allPrismicHomepage {
      edges {
        node {
          data {
            hero_image {
              # gatsby-image
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2880, srcSetBreakpoints: [320, 480, 768, 1024, 1200, 1440, 2280]) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationHeight
                    presentationWidth
                  }
                }
              }
              # gatsby-plugin-image
              # localFile {
              #   childImageSharp {
              #     gatsbyImageData
              #   }
              # }
              alt
            }
            hero_title {
              raw
            }
            hero_subtitle {
              raw
            }
            info_1
            info_2
            info_3
            quote {
              raw
            }
            author {
              raw
            }
            next_page {
              document {
                ... on PrismicPage {
                  id
                  uid
                  type
                  href
                }
              }
              slug
            }
          }
        }
      }
      nodes {
        uid
        data {
          body {
            ... on PrismicHomepageBodySectionTitle {
              id
              slice_type
              primary {
                background_color
                description {
                  raw
                }
                title {
                  raw
                }
              }
            }
            ... on PrismicHomepageBodySplitSections {
              id
              slice_type
              primary {
                background_color
                add_bottom_padding
              }
              items {
                desktop_layout
                mobile_layout
                text_alignment
                background_color
                background_image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 90) {
                        aspectRatio
                        base64
                        src
                        srcWebp
                      }
                    }
                  }
                  alt
                }
                title {
                  raw
                }
                text {
                  raw
                }
                captions_desktop {
                  raw
                }
                captions_mobile {
                  raw
                }
                content_image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 90) {
                        aspectRatio
                        base64
                        src
                        srcWebp
                        srcSet
                        srcSetWebp
                      }
                      original {
                        height
                        width
                      }
                    }
                  }
                  alt
                }
                parallax_speed
              }
            }
            ... on PrismicHomepageBodyNarrowImage {
              id
              slice_type
              primary {
                image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 90) {
                        #   ...GatsbyImageSharpFluid_withWebp
                        aspectRatio
                        base64
                        src
                        srcWebp
                        srcSet
                        srcSetWebp
                      }
                      original {
                        height
                        width
                      }
                    }
                  }
                  alt
                }
                background_color
              }
            }
            ... on PrismicHomepageBodyFullWidthImage {
              id
              slice_type
              primary {
                background_color
                captions {
                  raw
                }
                captions_mobile {
                  raw
                }
                description {
                  raw
                }
                image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 90) {
                        aspectRatio
                        base64
                        srcWebp
                        src
                        srcWebp
                        srcSet
                        srcSetWebp
                      }
                      original {
                        height
                        width
                      }
                    }
                  }
                  alt
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Homepage
