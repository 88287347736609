import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import BackgroundImage from 'gatsby-background-image'
import { FluidObject } from 'gatsby-image'
import { m } from 'framer-motion'
import classNames from 'classnames/bind'

import { setState } from 'store/store'

import LoFi from 'components/ui/LoFi'

import { IntroTypes, ImageTypes } from './HeroTypes'

import * as s from './Intro.module.scss'

const cn = classNames.bind(s)

const outerVariants = {
  hidden: {
    y: '100%',
  },
  show: {
    y: '0%',
    transition: {
      type: 'tween',
      duration: 2,
      ease: [0.645, 0.045, 0.355, 1],
    },
  },
}

const innerVariants = {
  hidden: {
    y: '-100%',
  },
  show: {
    y: '0%',
    transition: {
      type: 'tween',
      duration: 2,
      ease: [0.645, 0.045, 0.355, 1],
    },
  },
}

const imagesVariants = {
  hidden: { scale: 1.1 },
  show: {
    scale: 1,
    transition: {
      type: 'tween',
      duration: 6,
      ease: 'linear',
    },
  },
}

const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 2,
    },
  },
}

const itemVariants = {
  hidden: {
    opacity: 0,
    // filter: 'brightness(180%)',
  },
  show: {
    opacity: 1,
    // filter: 'brightness(100%)',
    transition: {
      type: 'tween',
      duration: 1.5,
      // ease: [0.6, 0.04, 0.98, 0.335],
      // ease: 'easeInOut',
    },
  },
}

const Image = ({ desktop, mobile, onImageLoaded, critical = false }: ImageTypes) => {
  const sources = [
    mobile,
    {
      ...desktop,
      media: `(min-width: 1024px)`,
    },
  ]

  return (
    <BackgroundImage
      className={cn('bg')}
      fluid={sources}
      fadeIn={false}
      style={{ position: 'absolute' }}
      onLoad={onImageLoaded}
    />
  )
}

const Intro = ({ introImages, onIntroImagesAnimated }: IntroTypes) => {
  const [randomImages, setRandomImages] = useState<[] | { desktop: FluidObject; mobile: FluidObject }[]>([])
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [animationComplete, setAnimationComplete] = useState(false)
  const loadedImages = useRef(0)

  useEffect(() => {
    setState({ shouldBlockScroll: true })
    const desktopImages = introImages.desktop.edges.sort(() => 0.5 - Math.random())
    const mobileImages = introImages.mobile.edges.sort(() => 0.5 - Math.random())

    let images = []
    for (let i = 0; i < desktopImages.length; i++) {
      images.push({
        desktop: desktopImages[i].node.childImageSharp.fluid,
        mobile: mobileImages[i].node.childImageSharp.fluid,
      })
    }

    setRandomImages(images.slice(0, 1))
  }, [introImages])

  const onImageLoaded = useCallback(() => {
    loadedImages.current = loadedImages.current + 1
    loadedImages.current === randomImages.length && setImagesLoaded(true)
  }, [randomImages])

  const onAnimationComplete = useCallback(() => {
    setTimeout(() => {
      setState({ hideLoader: true })
      onIntroImagesAnimated()
      setAnimationComplete(true)
      setState({ animateHeader: true })
    }, 550)
  }, [])

  return (
    <>
      <m.div
        className={cn('intro')}
        animate={animationComplete && { opacity: 0 }}
        transition={{ from: 1, duration: 0, delay: 2 }}
      >
        <m.div className={cn('outer')} variants={outerVariants} initial='hidden' animate={imagesLoaded && 'show'}>
          <m.div className={cn('inner')} variants={innerVariants} initial='hidden' animate={imagesLoaded && 'show'}>
            <m.div className={cn('images')} variants={imagesVariants} initial='hidden' animate={imagesLoaded && 'show'}>
              <m.div
                variants={containerVariants}
                initial='hidden'
                animate={imagesLoaded && 'show'}
                onAnimationComplete={onAnimationComplete}
              >
                {randomImages.map((image, i) => (
                  <m.div key={i} className={cn('image')} variants={itemVariants}>
                    <Image desktop={image.desktop} mobile={image.mobile} onImageLoaded={onImageLoaded} critical />
                  </m.div>
                ))}
              </m.div>
            </m.div>
          </m.div>
        </m.div>
        <LoFi isActive absolute intensity={0.4} fadeIn />
      </m.div>
    </>
  )
}

export default Intro
