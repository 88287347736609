import React from 'react'
import classNames from 'classnames/bind'

import { useStore } from 'store/useStore'

import { Caption } from 'components/ui/Text'

import * as s from './Loader.module.scss'
const cn = classNames.bind(s)

const Loader = () => {
  const isInitialLoad = useStore(s => s.isInitialLoad)
  const hideLoader = useStore(s => s.hideLoader)

  if (!isInitialLoad) return null
  return (
    <Caption as='p' className={cn('loading', { loaded: hideLoader })}>
      <span>
        <span className={cn('char')}>L</span>
        <span className={cn('char')}>o</span>
        <span className={cn('char')}>a</span>
        <span className={cn('char')}>d</span>
        <span className={cn('char')}>i</span>
        <span className={cn('char')}>n</span>
        <span className={cn('char')}>g</span>
      </span>
    </Caption>
  )
}

export default Loader
