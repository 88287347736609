// extracted by mini-css-extract-plugin
export var colorBgGrey = "#f7f7f7";
export var colorBgWhite = "#fff";
export var colorBgBlue = "#406080";
export var colorSkyBlue = "#406080";
export var colorDarkBlue = "#203040";
export var colorIce = "#dae0e6";
export var colorGranite = "#b1bcc4";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var content = "Hero-module--content--1Var6";
export var hero = "Hero-module--hero--3Zjdi";
export var picture = "Hero-module--picture--1cRDw";
export var title = "Hero-module--title--wSHiv";
export var char = "Hero-module--char--1EvWE";
export var subtitle = "Hero-module--subtitle--3M8rA";
export var cover = "Hero-module--cover--2pu3i";
export var heroImage = "Hero-module--heroImage--2xrHs";
export var caption = "Hero-module--caption--3bNRc";