import React, { useEffect, memo } from 'react'
import { useInView } from 'react-intersection-observer'

import { setState } from 'store/store'

const HeroTitle: React.FC = ({ children }) => {
  const { ref, inView } = useInView({})

  useEffect(() => {
    if (
      document.documentElement.classList.value.includes('has-scroll-smooth') &&
      document.querySelector('[data-smooth-item]')?.getAttribute('data-smooth-item-inview') === null
    )
      return
    setState({ customHeader: !inView })
  }, [inView])

  return <div ref={ref}>{children}</div>
}

export default memo(HeroTitle)
