// extracted by mini-css-extract-plugin
export var colorBgGrey = "#f7f7f7";
export var colorBgWhite = "#fff";
export var colorBgBlue = "#406080";
export var colorSkyBlue = "#406080";
export var colorDarkBlue = "#203040";
export var colorIce = "#dae0e6";
export var colorGranite = "#b1bcc4";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var loading = "Loader-module--loading--Bc4o6";
export var char = "Loader-module--char--1nfT-";
export var fromBottom = "Loader-module--fromBottom--1fy7o";
export var fadeIn = "Loader-module--fadeIn--2aTfF";
export var loaded = "Loader-module--loaded--KBpt0";
export var toTop = "Loader-module--toTop--2IznA";
export var fadeOut = "Loader-module--fadeOut--QkK4q";