import React, { useState, useCallback, useEffect } from 'react'
import classNames from 'classnames/bind'
import { Helmet } from 'react-helmet'
import { m } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { useStore } from 'store/useStore'
import { setState } from 'store/store'

import AspectRatio from 'components/core/AspectRatio'
import Parallax from 'components/ui/Parallax'
import { Title, Subheading } from 'components/ui/Text'

import HeroTitle from './HeroTitle'
import Intro from './Intro'

import { HeroTypes } from './HeroTypes'

import * as s from './Hero.module.scss'
import Captions from '../Captions'

const cn = classNames.bind(s)

const contentVariants = {
  hidden: { y: '100vh' },
  show: {
    y: '0vh',
    transition: {
      type: 'tween',
      ease: [0.77, 0, 0.175, 1],
      delay: 0.5,
      duration: 1.6,
    },
  },
}

const charsVariants = {
  hidden: { opacity: 0 },
  show: (isInitialLoad: boolean) => ({
    opacity: 1,
    duration: 2,
    transition: {
      staggerChildren: isInitialLoad ? 0.13 : 0.1,
      delay: isInitialLoad ? 1 : 0.2,
      delayChildren: isInitialLoad ? 1.3 : 0.2,
    },
  }),
}

const charVariants = {
  hidden: {
    y: '100%',
    scale: 0.95,
    skewY: '4deg',
    opacity: 0,
  },
  show: (isInitialLoad: boolean) => ({
    y: '0%',
    scale: 1,
    skewY: '0deg',
    opacity: 1,
    transition: {
      type: 'tween',
      ease: [0.165, 0.84, 0.44, 1],
      duration: isInitialLoad ? 1.5 : 1.2,
    },
  }),
}

const subtitleVariants = {
  hidden: { opacity: 0 },
  show: (isInitialLoad: boolean) => ({
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 1,
      delay: isInitialLoad ? 2 : 0.5,
    },
  }),
}

const coverVariants = {
  hidden: { scaleY: 1 },
  show: {
    scaleY: 0,
    transition: {
      type: 'tween',
      ease: [0.075, 0.82, 0.165, 1],
      duration: 1,
      delay: 2,
    },
  },
}

const heroImageVariants = {
  hidden: {
    scale: 1.1,
  },
  show: (isInitialLoad: boolean) => ({
    scale: 1,
    transition: {
      type: 'tween',
      scale: {
        ease: [0.075, 0.82, 0.165, 1],
        duration: 3,
        delay: isInitialLoad ? 2 : 0,
      },
    },
  }),
}

const Hero = ({ title, subtitle, image, alt, introImages }: HeroTypes) => {
  const isInitialLoad = useStore(s => s.isInitialLoad)
  const chars = title.split('')
  const [animateIn, setAnimateIn] = useState(false)
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  // If show intro - first page load
  const onIntroImagesAnimated = useCallback(() => isInitialLoad && setAnimateIn(true), [isInitialLoad])

  // No intro, normal page load
  useEffect(() => {
    !isInitialLoad && inView && setAnimateIn(true)
  }, [inView, isInitialLoad])

  return (
    <>
      <div className={cn('hero')} ref={ref} data-smooth-item>
        <m.div
          className={cn('content')}
          variants={contentVariants}
          initial={isInitialLoad && 'hidden'}
          animate={isInitialLoad && animateIn && 'show'}
        >
          <HeroTitle>
            <>
              <div className={cn('title')}>
                <Title>
                  <m.span
                    variants={charsVariants}
                    initial={'hidden'}
                    animate={animateIn && 'show'}
                    custom={isInitialLoad}
                    onAnimationComplete={() => {
                      isInitialLoad && setState({ isInitialLoad: false })
                      setState({ shouldBlockScroll: false })
                    }}
                  >
                    {chars.map((char, c) => (
                      <m.span key={char + c} className={cn('char')} variants={charVariants} custom={isInitialLoad}>
                        {char}
                      </m.span>
                    ))}
                  </m.span>
                </Title>
              </div>
              <m.div
                className={cn('subtitle')}
                variants={subtitleVariants}
                initial='hidden'
                animate={animateIn && 'show'}
                custom={isInitialLoad}
              >
                <div>
                  <Subheading as='h2'>{subtitle}</Subheading>
                </div>
              </m.div>
            </>
          </HeroTitle>
          {isInitialLoad && (
            <m.div className={cn('cover')} variants={coverVariants} initial='hidden' animate={animateIn && 'show'} />
          )}
        </m.div>
        <Parallax speed={0.65} zoomOut={0.26}>
          <AspectRatio aspectRatio={image.aspectRatio}>
            <m.div
              className={cn('heroImage')}
              variants={heroImageVariants}
              initial='hidden'
              animate={animateIn && 'show'}
              custom={isInitialLoad}
            >
              <picture className={cn('picture')}>
                <source srcSet={image.srcSetWebp} type='image/webp' />
                <source srcSet={image.srcSet} type='image/jpeg' />
                <img
                  src={image.base64}
                  alt={alt}
                  height={image.presentationalHeight}
                  width={image.presentationalWidth}
                />
              </picture>
            </m.div>
          </AspectRatio>
        </Parallax>
        <div className={cn('caption')}>
          <Captions captions={[{ text: 'Above: ' + alt }]} />
        </div>
        {isInitialLoad && (
          <>
            <Helmet>
              <html data-intro />
            </Helmet>
            <Intro introImages={introImages} onIntroImagesAnimated={onIntroImagesAnimated} />
          </>
        )}
      </div>
    </>
  )
}

export default Hero
